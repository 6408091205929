.technology_component {
    width: 100vw;
    margin: 5rem 0;

    span.technology {
        display: inline-block;
        padding: 0.4rem 0.8rem;
        margin: 0.4rem;
        border: 0.15rem solid map-get($color, 0);
        border-radius: 0.3rem;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;

        &.featured {
            background-color: map-get($color, 0);
            color: #ffffff !important;
        }
    }
}

div.dark .technology_component {
    span.technology {
        color: map-get($light_color, 0);
    }
}

div.light .technology_component {
    span.technology {
        color: map-get($dark_color, 0);
    }
}