@keyframes revealFromLeft {
    0% {
        transform: translateX(-1em);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes reveal {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaleVertical {
    0% {
        transform: scaleY(0);

    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes revealScaleUp {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }



    50% {
        opacity: 1;
        transform: scale(1.3);

    }

    100% {
        opacity: 1;
        transform: scale(1);

    }
}



.experience_component {
    border-bottom: 0.3rem solid map-get($color, 0);
    display: block;

    ul {
        position: relative;
        margin: 0 auto;
        animation: reveal 2s 0 ease both 1;

        &:before {
            content: "";
            border-left: 5px solid map-get($color, 0);
            left: -0.25em;
            top: 2em;
            height: 100%;
            position: absolute;
            bottom: 0;
            top: 0;
            transform-origin: 0 0;
            animation: scaleVertical 3s 1s ease both 1;
        }

        li {

            padding-left: 3rem;
            padding-bottom: 2rem;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0px;
                left: -1.3em;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 5px solid map-get($color, 0);
                animation: revealScaleUp 0.75s 0s ease both 1;
            }

            .content {
                animation: revealFromLeft 0.5s 0s ease both 1;
                padding-top: 0.4rem;

                h2 {

                    letter-spacing: 0.03em;
                    font-weight: 400;
                    margin-top: 1.3rem;
                    margin-bottom: 0.8rem;
                    column-gap: 0.5rem;


                    span {
                        &:nth-child(1) {
                            font-weight: 600;

                        }

                        &:nth-child(2) {
                            font-weight: 600;
                            color: map-get($color, 0);
                            align-self: center;
                        }
                    }
                }

                h3 {
                    background-color: map-get($color, 0);
                    display: inline-block;
                    font-weight: 500;
                    padding: 0.6em 1em;
                    color: #fff;
                    font-size: 0.8rem;
                    border-radius: 50rem;
                }

                p {
                    line-height: 2rem;
                    font-weight: 500;
                }

                div.technologies {
                    margin: 1rem 0;
                    display: flex;
                    flex-direction: row;
                    gap: 0.5rem;
                    flex-wrap: wrap;

                    span {
                        border: 0.15rem solid map-get($color, 0);

                        font-weight: 500;
                        padding: 0.5em 0.9em;
                        font-size: 0.8rem;
                        border-radius: 50rem;
                        color: #000;


                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

            @for $i from 1 through 10 {
                &:nth-child(#{$i}):before {
                    animation-delay: #{$i}s;
                }

                &:nth-child(#{$i}) .content {
                    animation-delay: #{$i + 0.10}s;
                }
            }
        }
    }


}

div.light .experience_component {
    ul {
        li {
            &:before {
                background: none repeat scroll 0% 0% map-get($light_color, 0);
            }

            .content {
                h2 {
                    color: map-get($dark_color, 0);
                }

                p {
                    color: map-get($dark_color, 0);
                }

                div.technologies {
                    span {
                        color: map-get($dark_color, 0);
                    }
                }
            }
        }
    }
}

div.dark .experience_component {

    ul {
        li {
            &:before {
                background: none repeat scroll 0% 0% map-get($dark_color, 0);
            }

            .content {
                h2 {
                    color: map-get($light_color, 0);
                }

                p {
                    color: map-get($light_color, 0);
                }

                div.technologies {
                    span {
                        color: map-get($light_color, 0);
                    }
                }
            }
        }
    }
}

.experience_component {
    @media(max-width: 40rem) {
        ul {
            width: 80vw;
        }
    }

    @media(min-width: 40rem)and (max-width: 60rem) {
        ul {
            width: 33rem;
        }
    }

    @media(min-width: 60rem)and (max-width: 80rem) {
        ul {
            width: 53rem;
        }
    }

    @media(min-width: 80rem)and (max-width: 100rem) {
        ul {
            width: 73rem;
        }
    }

    @media(min-width: 100rem) {
        ul {
            width: 93rem;
        }
    }

    @media(max-width: 60rem) {
        ul {
            li {
                .content {
                    h2 {
                        display: grid;
                        font-size: 1.2rem;
                        grid-template-columns: auto 1fr;
                        row-gap: 1rem;
                        grid-template-rows: auto auto;
                        line-height: 1.5rem;

                        span:first-child {
                            grid-column: span 2;
                        }

                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }

    @media(min-width: 60rem) {
        ul {
            li {
                .content {
                    h2 {
                        display: grid;
                        font-size: 1.5rem;
                        grid-template-columns: auto auto 1fr;
                        line-height: 1.8rem;
                    }
                }
            }
        }
    }
}