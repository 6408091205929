.header_component {
    box-sizing: border-box;

    div.container {
        display: grid;
        margin: auto;
        grid-template-columns: 1fr auto auto;
        align-items: center;

        a.heading {
            text-transform: uppercase;
            font-weight: 600;
        }

        nav {
            font-size: 1.1rem;
            letter-spacing: 0.1rem;
            grid-gap: 3rem;
            line-height: 1rem;

            a {
                &:hover {
                    color: map-get($color, 0);
                }
            }
        }

        a.contact {
            font-weight: 500;
            display: grid;
            grid-template-columns: auto auto;
            align-items: center;
            border: 0.2rem solid map-get($color, 0);

            &:hover {
                background: map-get($color, 0);

                span {
                    color: #fff !important;
                }
            }
        }

        button {
            border: none;
            background: none;
            font-weight: 600;
            align-items: center;
            cursor: pointer;
        }
    }
}

div.dark .header_component {

    color: map-get($light_color, 0);

    span {
        color: map-get($light_color, 0);
    }

    button span {
        color: #ffd074;
    }
}

div.light .header_component {

    color: map-get($dark_color, 0);

    span {
        color: map-get($dark_color, 0);
    }
}


.header_component {
    @media (max-width: 40rem) {
        div.container {
            width: 90vw;
        }
    }

    @media(min-width: 40rem)and (max-width: 60rem) {
        div.container {
            width: 35rem;
        }
    }

    @media(min-width: 60rem)and (max-width: 80rem) {

        div.container {
            width: 55rem;
        }
    }

    @media(min-width: 80rem)and (max-width: 100rem) {
        div.container {
            width: 75rem;
        }
    }

    @media(min-width: 100rem) {
        div.container {
            width: 95rem;
        }
    }

    @media (max-width: 40rem) {
        padding: 1rem;

        div.container {
            column-gap: 0.5rem;

            a.heading {
                font-size: 1.1rem;
                line-height: 1.1rem;
                letter-spacing: 0.1rem;
            }

            nav {
                font-size: 1rem;
                letter-spacing: 0.1rem;
                grid-gap: 2rem;
                line-height: 1rem;
            }

            a.contact {
                font-size: 0.9rem;
                column-gap: 0.4rem;
                padding: 0.5rem 1rem;
                border-radius: 50rem;
            }

            button {
                font-size: 1.1rem;
                line-height: 1.1rem;
            }
        }

    }

    @media(min-width: 40rem) {
        padding: 2rem;

        div.container {
            column-gap: 2rem;

            a.heading {
                font-size: 1.2rem;
                line-height: 1.2rem;
                letter-spacing: 0.2rem;
            }

            nav {
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
                grid-gap: 3rem;
                line-height: 1rem;
            }

            a.contact {
                font-size: 1.1rem;
                column-gap: 0.5rem;
                padding: 0.8rem 1.5rem;
                border-radius: 50rem;
            }

            button {
                font-size: 1.2rem;
                line-height: 1.2rem;
            }
        }

    }
}