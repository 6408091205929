.project_component {
    padding-top: 4rem;

    div.container {
        width: 80vw;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 4rem;
        row-gap: 4rem;

        article {
            padding: 3rem;

            h1 {
                font-size: 1.5rem;
                font-weight: 500;
                letter-spacing: 0.1rem;
                line-height: 2.5rem;
                display: block;
                width: 65%;
                color: map-get($color, 0);
            }

            h2 {
                font-size: 1rem;
                font-weight: 500;
                letter-spacing: 0.05rem;
                margin-bottom: 1rem;
            }

            img {
                width: 100%;
                border-radius: 0.5rem;
                border: 0.1rem solid #000000;
                margin-top: 2rem;
            }
        }
    }
}

div.dark {
    .project_component {
        background-color: map-get($dark_color, 1);

        article {
            background-color: map-get($dark_color, 0);

            img {
                border-color: map-get($dark_color, 1);
            }
        }
    }
}

div.light {
    .project_component {
        background-color: map-get($light_color, 1);

        article {
            background-color: map-get($light_color, 0);

            img {
                border-color: map-get($light_color, 1);
            }
        }
    }
}